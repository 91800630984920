import React, { useState, useEffect } from "react";
import firebase from "../services/firebase";
import {
  createUserWithPwdProvider,
  syncUser,
  claimTicket,
  getUserOrganizerId,
} from "../services/UserService";
import { firebaseErrors } from "../services/firebase.constants";
import { cloneAsObject } from "../utils";
import { getOrganization, getListOfOrganizer } from "../services/OrganizerService";
import { findUserByEmail, findByEmailStatusOne, findByEmailStatusNotEqual2, updateUserProvider, findByEmailFirebaseAndDB, decodeJWT } from "../services/UserService";

export const AuthContext = React.createContext();

let currentProvider = '';

const AuthProvider = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [sent, setSent] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [resetCodeValid, setResetCodeValid] = useState(false);
  const [organizerId, setOrganizerId] = useState();
  const [organizerList, setOrganizerList] = useState({});
  const [selectedUserPermission, setSelectedUserPermission] = useState();
  const [userRole, setUserRole] = useState();
  const [userOrganizer, setUserOrganizer] = useState();
  const [userLocation, setUserLocation] = useState();
  const [refer, setRefer] = useState("");
  const [singUpEmail, setSingUpEmail] = useState();
  const [errorMessage, setErrorMessage] = useState(null)
  const [accept, setAccept] = useState({ marketing: false, smsVerification: false })
  const [menuAcessAutorizationContext, setMenuAcessAutorizationContext] = useState(false)
  const [all, setAll] = useState()
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    getLocation();

    const unsubscribe = firebase.auth().onIdTokenChanged(handleUser);
    return () => unsubscribe();
  }, []);

  const resetAllContext = async () => {
    // setLoggedIn(false);
    setLoading(false);
    setError(false);
    setSent(false);
    setPasswordChanged(false);
    setResetCodeValid(false);
    setRefer("");
    setSingUpEmail(null);
  };

  const loginWithToken = async (token) => {
    try {
      const decoded = await decodeJWT(token);
      const resToken = decoded.data.decoded;

      const userCredential = await firebase.auth().signInWithCustomToken(resToken.customToken);
  
      const user = userCredential.user;
  
      const formattedUser = await formatUser(user);
  
      const { uid, ...userWithoutUid } = formattedUser;
      const resUser = (await syncUser(uid, userWithoutUid)).data;
      if (currentProvider === 'google.com' && resUser.user && resUser.user.status === 2) {
        await firebase.auth().signOut();
        return { success: false, message: resUser.message };
      }
  
      localStorage.setItem("user", JSON.stringify(firebase.auth().currentUser));
      await ticketTransfer(user);
      await updateOrganizerId(user);
      setUser({ ...user, ...resUser });
      localStorage.setItem("currentUser", JSON.stringify(resUser));
  
      setLoggedIn(true);
      setLoading(false);
      setError(false);
  
      return { success: true, user: formattedUser };
    } catch (error) {
      console.error("Erro ao realizar login com token:", error);
      await signOut();
      setError(error);
      setLoading(false);
      return { success: false, message: error.message };
    }
  };

  const handleUser = async (rawUser) => { // rawUser is firebase.auth().currentUser
    setLoading(true);
    try {
      if (rawUser) {
        const user = await formatUser(rawUser);
        const { uid, ...userWithoutUid } = user;
        const resUser = (await syncUser(user.uid, userWithoutUid)).data;
        if (currentProvider === 'google.com' && resUser.user && resUser.user.status === 2) {
          setLoading(false)
          setUser(null);
          setLoggedIn(false);
          setPasswordChanged(false);
          localStorage.removeItem("user")
          setError({ message: resUser.message })
          return firebase
            .auth()
            .signOut()
            .then(() => handleUser(false));
        } else {
          localStorage.setItem("user", JSON.stringify(firebase.auth().currentUser));
          await ticketTransfer(user);
          await updateOrganizerId(user);
          setUser({ ...user, ...resUser });
          localStorage.setItem("currentUser", JSON.stringify(resUser));
          setLoading(false);
          setError(false);
          setLoggedIn(true);
          return user;
        }

      } else {
        setOrganizerId(null);
        setUser(null);
        setLoggedIn(false);
        setLoading(false);
        setOrganizerList({});
        setUserOrganizer();
        setSelectedUserPermission();
        setUserRole();
        localStorage.removeItem("selectedOrganizer");
        return false;
      }
    } catch (error) {

      console.log("error.response", error.response);

      // setOrganizerId(null);
      // setUser(null);
      // setLoggedIn(false);
      // setLoading(false);
      await signOut();
      setError(error);
      return false;
    }
  };

  const signInWithGoogle = async () => {
    setLoading(true);
    if (navigator.userAgent.includes("Instagram")) {
      // https://stackoverflow.com/questions/23248525/setting-a-custom-useragent-in-html-or-javascript
      navigator.__defineGetter__('userAgent', function () {
        return "Mozilla/5.0 (Windows NT 6.2; WOW64; rv:28.0) Gecko/20100101 Firefox/28.0)"
      });
      return firebase
        .auth()
        .signInWithRedirect(new firebase.auth.GoogleAuthProvider())
        .then((response) => {
          setError(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    } else {
      return firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then((response) => {
          currentProvider = response.additionalUserInfo.providerId
          setError(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    }
  };

  const signInEmailAndPassword = async (data) => {
    try {

      setLoading(true);
      const { foundInDatabase, foundInFirebase } = await findByEmailFirebaseAndDB(data.email);
      const user = await findByEmailStatusNotEqual2(data.email);
      return firebase
        .auth()
        .signInWithEmailAndPassword(data.email, data.senha)
        .then((userCredential) => {
          setError(false);
        })
        .catch((error) => {
          if (firebaseErrors[error.code]) {
            error.message = firebaseErrors[error.code];
          }
          if (user.data.provider === "apple.com") {
            error.message = firebaseErrors["auth/wrong-provider-apple"];
          }
          setError(error);
          setLoading(false);
          throw error;
        });
    } catch (error) {
      setError(error);
      setLoading(false);
      return error;

    }

  };

  const signInWithApple = async () => {
    setLoading(true);
    const provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    if (navigator.userAgent.includes("Instagram")) {
      return firebase
        .auth()
        .signInWithRedirect(provider)
        .then((response) => {
          setError(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    } else {
      return firebase
        .auth()
        .signInWithPopup(provider)
        .then((response) => {
          setError(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          setLoading(false);
        });
    }
  };

  const confirmPasswordReset = async ({ code, new_password }, email) => {
    const payload = {
      provider: 'password'
    }
    return firebase
      .auth()
      .confirmPasswordReset(code, new_password)
      .then(async function () {
        setPasswordChanged(true);
        await updateUserProvider(email, payload)
      })
      .catch(function (error) {
        setPasswordChanged(false);
        setError(error);
      });
  };

  const verifyPasswordResetCode = async (code) => {
    return firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(function (email) {
        setResetCodeValid(true);
        return email;
      })
      .catch(function (error) {
        setError(error);
        setResetCodeValid(false);
        // Invalid code
      });
  };

  const userPasswordReset = async (data) => {
    setLoading(true);
    return firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.current_password)
      .then((userCredential) => {
        userCredential.user
          .updatePassword(data.new_password)
          .then(function () {
            setPasswordChanged(true);
            // Update successful.
          })
          .catch(function (error) {
            setPasswordChanged(false);
            // An error happened.
          });

        setError(false);
      })
      .catch((error) => {
        let message_error = { message: "Invalid current password" };
        setError(message_error);
        // setError(error);
        setLoading(false);
        setPasswordChanged(false);
        /* var errorCode = error.code;
        var errorMessage = error.message; */
        console.log(error);
      });
  };

  const signUpEmailAndPassword = async (data) => {

    const { accept, ...payload } = data;

    setAccept(accept)

    setLoading(true);
    const response = await createUserWithPwdProvider(data).catch((error) => {
      setError(error);
    });
    if (response && response.data) {
      setSent(true);
      setError(false);
      setSingUpEmail(response.data);
    }
    setLoading(false);
    return response;
  };

  const signInWithFacebook = async () => {
    setLoading(true);
    return firebase
      .auth()
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
        console.log(err);
      });
  };

  const signOut = async () => {
    setUser(null);
    setLoggedIn(false);
    setPasswordChanged(false);
    localStorage.removeItem("selectedOrganizer");
    setOrganizerList({});
    setSelectedUserPermission();
    setAll();
    return firebase
      .auth()
      .signOut()
      .then(() => handleUser(false));
  };

  const ticketTransfer = async (user) => {
    const ticketTransferToken = localStorage.getItem("transferToken");
    if (ticketTransferToken) {
      let data = {
        to_email: user.email,
        transfer_token: ticketTransferToken,
      };
      await claimTicket(data);
    }
    localStorage.removeItem("transferToken");
  };

  const passwordReset = async (email) => {
    setLoading(true);
    setError(false);
    setSent(false);
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then((response) => {
        setLoading(false);
        setSent(true);

      })
      .catch((err) => {
        setError(firebaseErrors[err.code]);
        console.log(err);
      });
  };

  const updateOrganizerId = async (user) => {
    setUserOrganizer();

    const organizer_id = await getUserOrganizerId(user.uid);
    const res_user = await findUserByEmail(user.email);
    const selectedOrg = localStorage.getItem("selectedOrganizer")
    const listOrg = await getListOfOrganizer(organizer_id.data, res_user.data.id);
    if(res_user.data.role === 'SADMIN')
      setOrganizerList({organizer:listOrg.data.organizer, permissions: []});
    else
      setOrganizerList(listOrg.data);
    if(selectedOrg === 'all' || res_user.data.role === 'SADMIN'){
      setAll('all')
      setUserRole(res_user.data.role);
    }

    if (selectedOrg && selectedOrg !== 'all') {
      if (listOrg && listOrg.data && listOrg.data.organizer) {
        if (listOrg.data.organizer.id === parseInt(selectedOrg))
          setUserRole(res_user.data.role);
        else {
          const org = listOrg.data.permissions.find((permission) => permission.organizer_id === parseInt(selectedOrg));
          if (org) {
            setSelectedUserPermission(org);
            setUserRole(org.role);
          }
        }
      } else if (listOrg && listOrg.data && listOrg.data.permissions && listOrg.data.permissions.length > 0) {
        const org = listOrg.data.permissions.find((permission) => permission.organizer_id === parseInt(selectedOrg));
        if (org) {
          setSelectedUserPermission(org);
          setUserRole(org.role);
        }
      }
      setOrganizerId(selectedOrg);
      const org = await getOrganization(selectedOrg);
      setUserOrganizer(org.data);
    }
    else if (organizer_id.data) {
      setOrganizerId(organizer_id.data);
      const org = await getOrganization(organizer_id.data);
      setUserOrganizer(org.data);
      setUserRole(res_user.data.role);
    } else if (listOrg && listOrg.data && listOrg.data.permissions && listOrg.data.permissions.length > 0) {
      const org = listOrg.data.permissions[0].organizer;
      setSelectedUserPermission(listOrg.data.permissions[0]);
      setOrganizerId(org.id);
      setUserRole(listOrg.data.permissions[0].role);
      setUserOrganizer(org);
    }
  };

  const handleChangeOrganizer = async (organizerId) => {
    const org_permission_selected = organizerList.permissions.find((permission) => permission.organizer_id === organizerId)
    if (org_permission_selected) {
      setUserRole(org_permission_selected.role);
    } else {
      setUserRole(user.role);
    }
    setSelectedUserPermission(org_permission_selected);
    setOrganizerId(organizerId);
    const listOrg = await getListOfOrganizer(user.organizer_id, user.id);
    if(user.role === 'SADMIN')
      setOrganizerList({organizer:listOrg.data.organizer, permissions: []});
    else
      setOrganizerList(listOrg.data);
    const org = await getOrganization(organizerId);
    setUserOrganizer(org.data);
  }

  const getLocation = () => {
    if (!navigator) return;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setUserLocation(JSON.stringify(cloneAsObject(pos.coords)));
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        signOut,
        loginWithToken,
        signInWithGoogle,
        signInWithFacebook,
        signInWithApple,
        signInEmailAndPassword,
        signUpEmailAndPassword,
        passwordReset,
        userPasswordReset,
        loading,
        user,
        setError,
        setLoading,
        error,
        setSent,
        sent,
        passwordChanged,
        setPasswordChanged,
        verifyPasswordResetCode,
        confirmPasswordReset,
        setResetCodeValid,
        resetCodeValid,
        setOrganizerId,
        organizerId,
        updateOrganizerId,
        userLocation,
        refer,
        setRefer,
        userOrganizer,
        setSingUpEmail,
        singUpEmail,
        resetAllContext,
        errorMessage,
        setUser,
        organizerList,
        setOrganizerList,
        handleChangeOrganizer,
        selectedUserPermission,
        setSelectedUserPermission,
        userRole,
        setUserRole,
        setMenuAcessAutorizationContext,
        menuAcessAutorizationContext,
        all,
        setAll,
        refresh,
        setRefresh
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

async function formatUser(user) {
  return {
    uid: user.uid,
    email: user.email,
    name: user.displayName || user.email.split("@")[0],
    provider: user.providerData[0].providerId,
  };
}

export default AuthProvider;
